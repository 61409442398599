@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&family=Oxygen:wght@300;400;700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Oxygen', sans-serif;
  /* padding-top: 70px; */
}

@layer components {
  .flex-col-center {
    @apply flex flex-col justify-center items-center;
  }
  .flex-row-center {
    @apply flex flex-row justify-center items-center;
  }
  .section {
    @apply max-h-[980px];
  }
  .home-layout {
    @apply space-y-20;
  }
}
