.nav {
  transition: background-color 0.7s ease-in-out;
}

.nav.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
}
